import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "../../../../../api/axios";
import { reciepeColumns } from "../../datatablesource";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const RecipesDatatable = () => {
  const [recipesData, setRecipesData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRecipeId, setSelectedRecipeId] = useState(null);
  const [editingRecipeId, setEditingRecipeId] = useState(null);
  const [recipeName, setRecipeName] = useState('');
  const [recipeIngredients, setRecipeIngredients] = useState([{ id: Date.now(), ingredientId: '', quantity: '', unit: '', unitId: null }]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const errRef = useRef();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchData();
    fetchIngredients();
    fetchUnits();
  }, []);

  const fetchData = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const response = await axios.get(`businessEntities/getAllRecipesViaRestoId/${restaurantId}`);
      setRecipesData(response.data.recipes);
    } catch (error) {
      console.error("Error fetching recipes data:", error);
    }
  };

  const fetchIngredients = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const response = await axios.get(`businessEntities/getAllIngredientsViaRestoId/${restaurantId}`);
      setIngredientOptions(response.data.ingredients || []);
    } catch (error) {
      console.error("Error fetching ingredients data:", error);
    }
  };

  const fetchUnits = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const response = await axios.get(`businessEntities/getAllUnitsViaRestoId/${restaurantId}`);
      setUnitOptions(response.data.units || []);
    } catch (error) {
      console.error("Error fetching units data:", error);
    }
  };

  const handleAddRecipe = () => {
    resetModal();
    setOpenModal(true);
  };

  const handleEditRecipe = (recipe) => {
    setRecipeName(recipe.recipeName);
    setRecipeIngredients(recipe.ingredients.map(ingredient => ({
      id: ingredient.ingredientId,
      ingredientId: ingredient.ingredientId,
      ingredientName: ingredient.ingredientName,
      quantity: ingredient.quantity,
      unit: ingredient.unitName,
      unitId: ingredient.unitId
    })));

    setEditingRecipeId(recipe.recipeId);
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    resetModal();
  };

  const resetModal = () => {
    setRecipeName('');
    setRecipeIngredients([{ id: Date.now(), ingredientId: '', quantity: '', unit: '', unitId: null }]);
    setErrMsg('');
    setEditingRecipeId(null);
  };

  const handleAddIngredient = () => {
    setRecipeIngredients([...recipeIngredients, { id: Date.now(), ingredientId: '', quantity: '', unit: '', unitId: null }]);
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...recipeIngredients];
    newIngredients[index][field] = value;

    if (field === 'ingredientId') {
      const selectedIngredient = ingredientOptions.find(option => option.ingredientId === value);
      newIngredients[index].unit = selectedIngredient ? selectedIngredient.unitName : '';
      newIngredients[index].unitId = selectedIngredient ? selectedIngredient.unitId : '';
    }

    setRecipeIngredients(newIngredients);
  };

  const handleUnitChange = (index, unitName) => {
    const selectedUnit = unitOptions.find(unit => unit.unitName === unitName);
    const newIngredients = [...recipeIngredients];
    newIngredients[index].unit = unitName;
    newIngredients[index].unitId = selectedUnit ? selectedUnit.unitId : null;

    setRecipeIngredients(newIngredients);
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = recipeIngredients.filter((_, i) => i !== index);
    setRecipeIngredients(newIngredients);
  };

  const handleAddRecipeSubmit = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");

    const requestBody = {
      recipeName,
      ingredients: recipeIngredients.map(ingredient => ({
        menuIngredientId: ingredient.ingredientId,
        quantity: ingredient.quantity,
        unitId: ingredient.unitId,
      })),
      restaurantId,
    };

    if (!recipeName || recipeIngredients.some(ing => !ing.ingredientId || !ing.quantity || !ing.unitId)) {
      setErrMsg("All fields are required.");
      return;
    }

    try {
      if (editingRecipeId) {
        // Update recipe
        await axios.put(`businessEntities/updateRecipe/${editingRecipeId}`, requestBody);
        setSnackbarMessage("Recipe was updated successfully.");
      } else {
        // Add new recipe
        await axios.post('businessEntities/addRecipe', requestBody);
        setSnackbarMessage("Recipe was added successfully.");
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      handleCloseModal();
      fetchData();
    } catch (error) {
      console.error("Error adding/updating recipe:", error);
      setErrMsg("Operation failed.");
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedRecipeId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRecipeId(null);
  };

  const handleDeleteRecipe = async () => {
    try {
      const response = await axios.delete(`businessEntities/deleteRecipe/${selectedRecipeId}`, {
        headers: { "Content-Type": "application/json" },
        params: {
          businessEntityId: sessionStorage.getItem("configBizEntityId"),
        },
        withCredentials: false,
      });

      const successMessage = response.data.message || 'Recipe deleted successfully!';
      fetchData();
      handleCloseDeleteDialog();
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      handleCloseDeleteDialog();
      const errorMessage = error.response?.data?.message || 'Error deleting recipe!';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error("Error deleting recipe:", error);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="editButton" onClick={() => handleEditRecipe(params.row)}>Edit</div>
            <div className="deleteButton" onClick={() => handleOpenDeleteDialog(params.row.recipeId)}>Delete</div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle" style={{ display: "flex", alignItems: "center" }}>
        My Recipes
        <Link className="link" style={{ marginLeft: "10px" }} onClick={handleAddRecipe}>
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={recipesData}
        getRowId={(row) => row.recipeId}
        columns={reciepeColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-recipe-modal"
        aria-describedby="add-recipe-description"
        disableEscapeKeyDown
      >
        <div className="modal-container" style={{ padding: '20px', maxWidth: '900px', maxHeight: '90vh', margin: 'auto', backgroundColor: 'white', borderRadius: '8px', overflowY: 'auto' }}>
          <h6 id="add-recipe-modal">{editingRecipeId ? "Edit Recipe" : "Add New Recipe"}</h6>
          <p ref={errRef} className={errMsg ? "errmsg-login" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="recipe-name" style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
                Recipe Name:
              </label>
              <input
                id="recipe-name"
                type="text"
                value={recipeName}
                onChange={(e) => setRecipeName(e.target.value)}
                style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', boxSizing: 'border-box' }}
              />
            </div>
            {recipeIngredients.map((ingredient, index) => (
              <div key={ingredient.id} style={{ display: 'flex', marginBottom: '20px', alignItems: 'flex-end' }}>
                <Select
                  value={ingredient.ingredientId}
                  onChange={(e) => handleIngredientChange(index, 'ingredientId', e.target.value)}
                  displayEmpty
                  style={{ marginRight: '10px', width: '200px' }}
                >
                  <MenuItem value="" disabled>
                    Select Ingredient
                  </MenuItem>
                  {ingredientOptions.map((option) => (
                    <MenuItem key={option.ingredientId} value={option.ingredientId}>
                      {option.ingredientName}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  type="number"
                  value={ingredient.quantity}
                  onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
                  placeholder="Quantity"
                  style={{ marginRight: '10px', width: '100px' }}
                />
                <Select
                  value={ingredient.unit}
                  onChange={(e) => handleUnitChange(index, e.target.value)}
                  displayEmpty
                  style={{ marginRight: '10px', width: '150px' }}
                >
                  <MenuItem value="" disabled>
                    Select Unit
                  </MenuItem>
                  {unitOptions.map((option) => (
                    <MenuItem key={option.unitId} value={option.unitName}>
                      {option.unitName}
                    </MenuItem>
                  ))}
                </Select>
                <IconButton aria-label="delete" onClick={() => handleRemoveIngredient(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button variant="outlined" onClick={handleAddIngredient} startIcon={<AddIcon />}>
              Add Ingredient
            </Button>
          </div>
      
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleAddRecipeSubmit}>
              {editingRecipeId ? "Update Recipe" : "Add Recipe"}
            </Button>
            <Button variant="outlined" onClick={handleCloseModal} style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
          >
            <DialogTitle>Delete Recipe</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this recipe? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
              <Button onClick={handleDeleteRecipe} color="primary">Delete</Button>
            </DialogActions>
          </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

    </div>
  );
};

export default RecipesDatatable;
