import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./datatable.scss";
import axios from "../../../../../api/axios";
import { ingredientsColumns } from "../../datatablesource";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const IngredientsDatatable = () => {
  const [ingredientsData, setIngredientsData] = useState([]);
  const [units, setUnits] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [ingredientName, setIngredientName] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [deviationLimit, setDeviationLimit] = useState('');
  const [lowStockAlert, setLowStockAlert] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentIngredientId, setCurrentIngredientId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [ingredientToDelete, setIngredientToDelete] = useState(null);
  
  const errRef = useRef();

  useEffect(() => {
    fetchIngredientsData();
    fetchUnits();
  }, []);

  const fetchIngredientsData = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const response = await axios.get(
        `businessEntities/getAllIngredientsViaRestoId/${restaurantId}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );
      setIngredientsData(response.data.ingredients);
    } catch (error) {
      console.error("Error fetching ingredients data:", error);
      setSnackbarMessage("Failed to fetch ingredients.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchUnits = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const response = await axios.get(
        `businessEntities/getAllUnitsViaRestoId/${restaurantId}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );
      setUnits(response.data.units || []);
    } catch (error) {
      console.error("Error fetching units data:", error);
      setSnackbarMessage("Failed to fetch units.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOpenDialog = (ingredient = null) => {
    if (ingredient) {
      setEditMode(true);
      setCurrentIngredientId(ingredient.ingredientId);
      setIngredientName(ingredient.ingredientName);
      setSelectedUnit(ingredient.unitId);
      setDeviationLimit(ingredient.deviationLimit);
      setLowStockAlert(ingredient.threshold);
    } else {
      setEditMode(false);
      setCurrentIngredientId(null);
      setIngredientName('');
      setSelectedUnit('');
      setDeviationLimit('');
      setLowStockAlert('');
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrMsg('');
  };

  const handleIngredientSubmit = async () => {
    if (!ingredientName || !selectedUnit || !deviationLimit || !lowStockAlert) {
      setErrMsg("All fields are required.");
      setTimeout(() => setErrMsg(''), 3000);
      return;
    }

    if (isNaN(deviationLimit) || deviationLimit < 0 || deviationLimit > 100) {
      setErrMsg("Deviation limit must be between 0 and 100.");
      setTimeout(() => setErrMsg(''), 3000);
      return;
    }

    try {
      const payload = {
        ingredientName,
        unitId: selectedUnit,
        deviationLimit: parseFloat(deviationLimit),
        lowStockAlert: parseFloat(lowStockAlert),
        businessEntityId: sessionStorage.getItem("configBizEntityId"),
      };

      if (editMode) {
        await axios.put(`businessEntities/updateIngredient/${currentIngredientId}`, payload, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setSnackbarMessage("Ingredient updated successfully.");
      } else {
        await axios.post('businessEntities/addIngredient', payload, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setSnackbarMessage("Ingredient added successfully.");
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleCloseDialog();
      fetchIngredientsData();
    } catch (error) {
      console.error("Error saving ingredient:", error);
      setSnackbarMessage(editMode ? "Editing ingredient failed." : "Adding ingredient failed.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteIngredient = async () => {
    if (!ingredientToDelete) return;

    try {
      const response = await axios.delete(`businessEntities/deleteIngredient/${ingredientToDelete}`, {
        headers: { "Content-Type": "application/json" },
        params: {
          businessEntityId: sessionStorage.getItem("configBizEntityId"),
        },
        withCredentials: false,
      });
      const successMessage = response.data.message || 'Ingredient was deleted successfully!'; 
      fetchIngredientsData();
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting ingredient:", error);
      const errorMessage = error.response?.data?.message || 'Error deleting ingredient!';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setConfirmDialogOpen(false);
      setIngredientToDelete(null);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="editButton" onClick={() => handleOpenDialog(params.row)}>Edit</div>
            <div className="deleteButton" onClick={() => {
              setIngredientToDelete(params.row.ingredientId);              
              setConfirmDialogOpen(true);
            }}>Delete</div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle" style={{ display: 'flex', alignItems: 'center' }}>
        My Ingredients
        <Link className="link" style={{ marginLeft: '10px' }} onClick={() => handleOpenDialog()}>
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={ingredientsData}
        getRowId={(row) => row.ingredientId}
        columns={ingredientsColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this ingredient?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleDeleteIngredient} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{editMode ? "Edit Ingredient" : "Add New Ingredient"}</DialogTitle>
        <DialogContent>
          <p ref={errRef} className={errMsg ? "errmsg-login" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <div>
            <label htmlFor="ingredient-name">Ingredient Name:</label>
            <input
              id="ingredient-name"
              type="text"
              value={ingredientName}
              onChange={(e) => setIngredientName(e.target.value)}
              style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div>
            <label htmlFor="unit">Unit of Measurement:</label>
            <select
              className="dropdown"
              id="unit"
              value={selectedUnit}
              onChange={(e) => setSelectedUnit(e.target.value)}
              style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            >
              <option value="" disabled>Select Unit</option>
              {units.map((unit) => (
                <option key={unit.unitId} value={unit.unitId}>
                  {unit.unitName}
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <label htmlFor="deviation-limit">Deviation Limit (%):</label>
              <input
                id="deviation-limit"
                type="number"
                value={deviationLimit}
                onChange={(e) => setDeviationLimit(e.target.value)}
                style={{ width: '90%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label htmlFor="low-stock-alert">Low Stock Alert:</label>
              <input
                id="low-stock-alert"
                type="number"
                value={lowStockAlert}
                onChange={(e) => setLowStockAlert(e.target.value)}
                style={{ width: '90%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleIngredientSubmit} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default IngredientsDatatable;
