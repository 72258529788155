import BBQImage from "../../elements/bbq-image";


function GeneralFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion">
                                {/*One*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1" aria-expanded="false">
                                        What services does BuyByeQ offer??
                                    </button>
                                    <div id="FAQ1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                            BuyByeQ specializes in a wide array of services, including POS technology, hotels , property management and much more. Our expertise extends to creating innovative solutions tailored to meet the unique needs of our clients.
                                        </div>
                                    </div>
                                </div>
                                {/*Two*/}
                                <div className="accordion-item">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2" aria-expanded="true">
                                    What sets BuyByeQ apart from other restaurant solution companies?
                                    </button>
                                    <div id="FAQ2" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        BuyByeQ distinguishes itself through a combination of innovative solutions, client-centric approach, timely delivery, and a commitment to excellence. Our team is dedicated to transforming challenges into success stories, setting new standards in the restaurant technology.
                                        </div>
                                    </div>
                                </div>
                            
                                {/*Four*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4" aria-expanded="false">
                                    What industries does BuyByeQ cater to?
                                    </button>
                                    <div id="FAQ4" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        BuyByeQ's expertise spans across all restaurant,property and hotel technology and more. Our multidisciplinary approach allows us to tailor solutions for a wide range of sectors.
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5" aria-expanded="false">
                                    Who founded BuyByeQ, and when was it established?
                                    </button>
                                    <div id="FAQ5" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        BuyByeQ was founded by Jyoti Nayak in 2020. With a vision for creating a product - prominent restaurant management solution , Jyoti Nayak leads the company towards its success and excellence.
                                        </div>
                                    </div>
                                </div>
                                {/*Six*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6" aria-expanded="false">
                                    How can I contact BuyByeQ for inquiries ?
                                    </button>
                                    <div id="FAQ6" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Feel free to reach out to us through our Contact page on the website, where you can fill out a form or find our contact details. We welcome you to patnership inquiries and discussions.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <BBQImage src="images/faq-img-1.jpg" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function HelpFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion-1">
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1-1" aria-expanded="false">
                                    How can I get technical support for a subscribed product ?
                                    </button>
                                    <div id="FAQ1-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        If you require technical support , please reach out to our dedicated support team through the contact details provided in contact us page. We prioritize swift responses to ensure minimal disruptions.
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2-1" aria-expanded="false">
                                    Can I schedule a consultation with BuyByeQ to discuss my ideas to improve the app?
                                    </button>
                                    <div id="FAQ2-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                        Yes, we encourage it! You can schedule a consultation through our website, where we'll discuss your project ideas, goals, and provide insights on how BuyByeQ can bring your vision to life.
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <BBQImage src="images/faq-img-2.jpg" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionFAQContent() {
    return (
        <>
            <div className="tab-content">
                {/*1*/}
                <div className="tab-pane fade show active" id="day_1" role="tabpanel">
                    <GeneralFAQContent />
                </div>
                {/*2*/}
                <div className="tab-pane fade" id="day_2" role="tabpanel">
                    <HelpFAQContent />
                </div>
            </div>
        </>
    )
}

export default SectionFAQContent;