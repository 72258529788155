import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./datatable.scss";
import axios from "../../../../../api/axios";
import { unitsColumns } from "../../datatablesource";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const UnitsDatatable = () => {
  const [unitsData, setUnitsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newUnitName, setNewUnitName] = useState('');
  const [newUnitShortName, setNewUnitShortName] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [currentUnitId, setCurrentUnitId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const errRef = useRef();

  const fetchData = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const response = await axios.get(`businessEntities/getAllUnitsViaRestoId/${restaurantId}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setUnitsData(response.data.units);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddUnit = () => {
    setOpenModal(true);
    setNewUnitName('');
    setNewUnitShortName('');
    setCurrentUnitId(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddUnitSubmit = async () => {
    if (!newUnitName) {
      setErrMsg("Unit name is required.");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      return;
    }

    try {
      const url = currentUnitId ? `businessEntities/updateUnit/${currentUnitId}` : 'businessEntities/addUnit';
      const response = await axios[currentUnitId ? 'patch' : 'post'](
        url,
        {
          unitName: newUnitName.trim(),
          unitShortName: newUnitShortName|| '',
          businessEntityId: sessionStorage.getItem("configBizEntityId"),
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );

      if (response.status === 200) {
        handleCloseModal();
        fetchData();
        setSnackbarMessage('Unit saved successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error saving unit!';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error("Error:", error);
    }
  };

  const handleEditUnit = (params) => {
    setCurrentUnitId(params.row.unitId);
    setNewUnitName(params.row.unitName);
    setNewUnitShortName(params.row.unitShortName);
    setOpenModal(true);
  };

  const handleDeleteUnit = (unitId) => {
    setCurrentUnitId(unitId);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteUnit = async () => {
    try {
      const response = await axios.delete(`businessEntities/deleteUnit/${currentUnitId}`, {
        headers: { "Content-Type": "application/json" },
        params: {
          businessEntityId: sessionStorage.getItem("configBizEntityId"),
        },
        withCredentials: false,
      });
  
      const successMessage = response.data.message || 'Unit deleted successfully!'; 
  
      fetchData();
      setOpenDeleteDialog(false);
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error deleting unit!';
      setOpenDeleteDialog(false);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error("Error deleting unit:", error);
    }
  };
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="editButton" onClick={() => handleEditUnit(params)}>
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDeleteUnit(params.row.unitId)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle" style={{ display: 'flex', alignItems: 'center' }}>
        My Units
        <Link className="link" style={{ marginLeft: '10px' }} onClick={handleAddUnit}>
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={unitsData}
        getRowId={(row) => row.unitId}
        columns={unitsColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-unit-modal"
        aria-describedby="add-unit-description"
      >
        <div className="modal-container">
          <h6 id="add-unit-modal">{currentUnitId ? 'Edit Unit' : 'Add New Unit'}</h6>
          <div>
            <p
              ref={errRef}
              className={errMsg ? "errmsg-login" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <label htmlFor="unit-name">Unit of measurement:</label>
            <input
              id="unit-name"
              type="text"
              value={newUnitName}
              onChange={(e) => setNewUnitName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="short-name">Short Name:</label>
            <input
              id="short-name"
              type="text"
              value={newUnitShortName}
              onChange={(e) => setNewUnitShortName(e.target.value)}
            />
          </div>
          <Button variant="contained" onClick={handleAddUnitSubmit}>
            {currentUnitId ? 'Update Unit' : 'Save Unit'}
          </Button>
        </div>
      </Modal>
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Unit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this unit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteUnit} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UnitsDatatable;
