import "../list.scss"
import Sidebar from "../../../components/sidebar/Sidebar"
import Navbar from "../../../components/navbar/Navbar"
import InventoryDatatable from "../../../components/datatable/InventoryDatatable"

const RestoWithInventoryList = ({title}) => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <InventoryDatatable title={title}/>
      </div>
    </div>
  )
}

export default RestoWithInventoryList