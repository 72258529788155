import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { inventoryColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import "./datatable.scss";
import axios from "../../../../../api/axios";

const InventoryDatatable = () => {
  const [productData, setProductData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      try {
        const response = await axios.get(`businessEntities/getAllSubscribedRestoNProdsViaGroupId/${userData.Group_ID}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        sessionStorage.setItem('prodCount', response.data.allProdsNRestos.length);
        setProductData(response.data.allProdsNRestos);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              onClick={() => {
                sessionStorage.setItem('configBizEntityId', params.row.businessEntityId);
              }}
              to="/config/inventory/configure"
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">{'Configure Inventory'}</div>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle" style={{ display: 'flex', alignItems: 'center' }}>
        Inventory
      </div>

      <DataGrid
        className="datagrid"
        rows={productData}
        getRowId={(row) => row.bepm_id}
        columns={inventoryColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />
    </div>
  );
};

export default InventoryDatatable;
