import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./datatable.scss";
import axios from "../../../../../api/axios";
import { recipeMenuColumns } from "../../datatablesource";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';

const RecipesAndMenuDatatable = () => {
  const [recipesData, setRecipesData] = useState([]);
  const [menuItemsData, setMenuItemsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [containers, setContainers] = useState([]);
  const [editRecipeId, setEditRecipeId] = useState(null);
  const [linkedMenuItems, setLinkedMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const recipesResponse = await axios.get(
        `businessEntities/getAllRecipesAndMenuItemsViaRestoId/${restaurantId}`,
        { headers: { "Content-Type": "application/json" }, withCredentials: false }
      );
      setRecipesData(recipesResponse.data.recipes || []);
      const allLinkedMenuItems = recipesResponse.data.recipes.flatMap(recipe => recipe.menuItems.map(item => item.menuItem));
      setLinkedMenuItems(allLinkedMenuItems);

      const menuItemsResponse = await axios.get(
        `businessEntities/getAllMenuItems/${restaurantId}`,
        { headers: { "Content-Type": "application/json" }, withCredentials: false }
      );
      setMenuItemsData(menuItemsResponse.data.allMenus || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbarMessage("Error fetching data");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (recipeId) => {
    setContainers([]);
    setSelectedMenuItem('');
    const recipe = recipesData.find(r => r.recipeId === recipeId);
    if (recipe) {
        setSelectedRecipe(recipe.recipeName);
        setEditRecipeId(recipeId);
        const currentLinkedMenuItems = recipe.menuItems || [];
        setContainers(currentLinkedMenuItems.map(item => ({
            recipe: recipe.recipeName,
            menuItem: item.menuItem,
            menuId: item.menuItemId 
        })));
        const currentRecipeLinkedMenuItems = currentLinkedMenuItems.map(item => item.menuItem);
        const updatedMenuItemsData = menuItemsData.filter(menuItem => 
            !linkedMenuItems.includes(menuItem.menu_name) && 
            !currentRecipeLinkedMenuItems.includes(menuItem.menu_name)
        );
        setMenuItemsData(updatedMenuItemsData);
        setOpenModal(true);
    }
};


  const handleMenuItemSelect = (event) => {
    const menuItemName = event.target.value;
    const selectedMenuItemData = menuItemsData.find(menuItem => menuItem.menu_name === menuItemName);

    if (selectedMenuItemData && editRecipeId) {
        const newContainer = { 
            recipe: selectedRecipe, 
            menuItem: menuItemName, 
            menuId: selectedMenuItemData.menu_id 
        };
        setContainers([...containers, newContainer]);
        setMenuItemsData(menuItemsData.filter(menuItem => menuItem.menu_name !== menuItemName));
        setSelectedMenuItem('');
    }
};

const handleUpdate = async () => {
    try {
        const restaurantId = sessionStorage.getItem("configBizEntityId");
        const menuItems = containers.map(container => ({
            menu_id: container.menuId, 
            menuItem: container.menuItem 
        }));
        const recipeId = editRecipeId;

        console.log({
            recipeId,
            restaurantId,
            menuItems
        });

        await axios.post(`businessEntities/updateRecipeMenuLinks`, {
            recipeId,
            restaurantId,
            menuItems
        });

        setSnackbarMessage("Updated successfully!");
        setSnackbarOpen(true);
        setOpenModal(false);
        fetchData(); 
    } catch (error) {
        console.error("Error updating recipe-menu links:", error);
        setSnackbarMessage("Error Linking Menu To Recipe");
        setSnackbarOpen(true);
    }
};


  const handleRemoveContainer = (index) => {
    const updatedContainers = containers.filter((_, i) => i !== index);
    setContainers(updatedContainers);
    const removedMenuItem = containers[index].menuItem;
    setMenuItemsData(prevMenuItemsData => [...prevMenuItemsData, { menu_name: removedMenuItem }]);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRecipe('');
    setSelectedMenuItem('');
    setContainers([]);
    fetchData();
  };

  
  

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => (
        <div className="cellAction">
          <div className="editButton" onClick={() => handleEditClick(params.row.recipeId)}>
            Edit / View
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle" style={{ display: "flex", alignItems: "center" }}>
        Link Recipes To Menu Items
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={recipesData.map(recipe => ({ ...recipe, menuItems: recipe.menuItems || [] }))}
          getRowId={(row) => row.recipeId}
          columns={recipeMenuColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}

      <Modal
        open={openModal}
        disableEscapeKeyDown
        onBackdropClick={(event) => event.stopPropagation()}
        aria-labelledby="add-link-modal"
        aria-describedby="add-link-description"
      >
        <div className="modal-container" style={{ padding: '20px', maxWidth: '600px', margin: 'auto', backgroundColor: 'white', borderRadius: '8px' }}>
          <h6 id="add-link-modal">Link Menu Items to Recipe</h6>
          <div style={{ marginBottom: '20px' }}>
            <Select
              value={selectedRecipe}
              displayEmpty
              fullWidth
              variant="outlined"
              style={{ marginBottom: '20px', color: '#ffffff' }}
              disabled
            >
              <MenuItem value="" disabled style={{ color: '#ffffff' }}>
                Select Recipe
              </MenuItem>
              {recipesData.map((recipe) => (
                <MenuItem key={recipe.recipeId} value={recipe.recipeName} style={{ color: '#ffffff' }}>
                  {recipe.recipeName}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={selectedMenuItem}
              onChange={handleMenuItemSelect}
              displayEmpty
              fullWidth
              variant="outlined"
              style={{ marginBottom: '20px' }}
            >
              <MenuItem value="" disabled>Select Menu Item</MenuItem>
              {menuItemsData.map((menuItem) => (
                <MenuItem key={menuItem.menu_id} value={menuItem.menu_name}>
                  {menuItem.menu_name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div style={{ marginBottom: '20px' }}>
            {containers.length > 0 && (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {containers.map((container, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px 10px', borderRadius: '15px', backgroundColor: '#e0e0e0', marginBottom: '10px' }}>
                    <span style={{ flex: 1, textAlign: 'center' }}>{container.menuItem}</span>
                    <Button
                      variant="outlined"
                      onClick={() => handleRemoveContainer(index)}
                      style={{ padding: '0', minWidth: '20px', minHeight: '20px', borderRadius: '50%' }}
                    >
                      <CancelIcon fontSize="small" />
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" onClick={handleUpdate}>Update</Button>
            <Button variant="outlined" onClick={handleCloseModal}>Close</Button>
          </div>
        </div>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        autoHideDuration={6000}
      />
    </div>
  );
};

export default RecipesAndMenuDatatable;
