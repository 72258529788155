import Banner from "../../sections/common/banner";
// import SectionServices1 from "../../sections/home/index/section-services1";
import { bannerData } from "../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import SectionAboutCompany from "./about_section";

function AboutUsPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            <Banner _data={bannerData.about} />
            <SectionAboutCompany/>
            {/* <SectionServices1 /> */}
        </>
    )
}

export default AboutUsPage;