import React, { useState } from 'react';
import { GroupOutlined, MenuBookRounded, CategoryOutlined, Accessible, TableRestaurant } from '@mui/icons-material';
import './product-config.scss';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AddNewSpecialUser from './special/users/add_new_user';
import { userInputs } from "../../../dashboard/formSource";
import SpecialUsersList from './special/users/view_users_list';
import CategoryList from './special/category/category-list';
import MenuItemList from './special/menu/lists/menu-list';
import SectionList from './special/section/lists/section_list';
import TablesList from './special/tables/lists/table-list';

const tabsData = [

  {
    icon: <GroupOutlined />, label: "User Updation", content: <div style={{ height: '100vh', overflow: 'auto' }}>
      <><AddNewSpecialUser inputs={userInputs} /><SpecialUsersList /></>
    </div>
  },
  { icon: <CategoryOutlined />, label: "Category Updation", content: <CategoryList/> },
  { icon: <MenuBookRounded />, label: "Menu Updation", content: < MenuItemList/> },
  { icon: <Accessible />, label: "Section Updation", content: <SectionList/> },
  { icon: <TableRestaurant />, label: "Tables Updation", content: <TablesList/> },
];

const ProductConfigComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="tabs-container">
          <div className="tab-list">
            {tabsData.map((tab, index) => (
              <div
                key={index}
                className={`tab-item ${selectedTab === index ? 'selected' : ''}`}
                onClick={() => handleTabSelect(index)}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </div>
            ))}
          </div>

          <div className="tab-panels">
            {tabsData.map((tab, index) => (
              <div key={index} className={`tab-panel ${selectedTab === index ? 'selected' : ''}`}>
                {selectedTab === index && (
                  <div>
                    {/* <h5>{tab.label}</h5> */}
                    {tab.content}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductConfigComponent;
