import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner"

function ContactUsPage() {
    return (
        <>
            <Banner _data={bannerData.contact} />

            {/* Contact us START */}
            <div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer ">
                <div className="container">
                    {/* GOOGLE MAP & CONTACT FORM */}
                    <div className="section-content">
                        {/* CONTACT FORM*/}
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12  m-b30">
                                <div className="contact-info">
                                    {/* TITLE START */}
                                    <div className="section-head left">
                                        <div className="sx-head-s-title">Contact Us</div>
                                        <div className="sx-head-l-title">
                                            <h2 className="sx-title">Best restaurant solutions for you, Contact Our Office. </h2>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="row sx-contact-form-icon-bx">
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-telephone" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a  className="sx-icon-bx-title-info">Contact Phone</a>
                                                    <a href="tel:+919861094684" className="sx-icon-bx-title">+91 (986) 109 4684</a>
                                                    <a href="tel:+919937989195" className="sx-icon-bx-title">+91 (993) 798 9195</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-email-2" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a className="sx-icon-bx-title-info">Contact Mail</a>
                                                    <a className="sx-icon-bx-title">contact@buybyeq.com</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-map-location" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a  className="sx-icon-bx-title-info">Contact Location</a>
                                                    <a  className="sx-icon-bx-title">2nd Floor Spurmall, Bhubaneswar.</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-clock" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a  className="sx-icon-bx-title-info">Office Time</a>
                                                    <a  className="sx-icon-bx-title">
                                                        Mon- Fri: 7.00 - 22.00
                                                    </a>
                                                    <a  className="sx-icon-bx-title">
                                                        Sat-Sun: 9.00 - 20.00
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 m-b30">
                                <div className="contact-home1-right">
                                    <div className="contact-home-1-form sx-bg-light">
                                        <h4 className="sx-title">Get In Touch</h4>
                                        <form className="cons-contact-form2 form-transparent" method="post">
                                            <div className="input input-animate">
                                                <input type="text" name="username" id="name" required placeholder="Your Name" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="email" name="email" id="email" required placeholder="Your Email" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="text" name="phone" id="Phone" required placeholder="Phone Number" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input textarea input-animate">
                                                <textarea name="message" id="message" required placeholder="Your Message" defaultValue={""} />
                                                <span className="spin" />
                                            </div>
                                            <div className="sx-btn-center text-center p-t10">
                                                <button type="submit" className="site-button sx-btn-primary icon sx-btn-lg">
                                                    <i className="fa  fa-long-arrow-right" />
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact us END */}
            <div className="gmap-outline map-grayscale">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.902071933193!2d36.970258!3d-1.1364108396471067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e40cf9cf3f8a9eb!2sNairobi%2C%20Kenya!5e0!3m2!1sen!2sin!4v1643435460462!5m2!1sen!2sin"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    title="BuyByeQ Restaurant Solutions"
                />

            </div>

        </>
    )
}

export default ContactUsPage;