
import { publicUrlFor } from "../../../globals/constants";
import BBQImage from "../../elements/bbq-image";

function SectionAboutCompany() {
    return (
        <>
            <div className="section-full  p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx1-content-wrap">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-media">
                                        <div className="sx-dot-bx"><BBQImage src="images/about/dotted-pic.png" alt="" /></div>
                                        <div className="sx-ab-img-outer">
                                        <div className="sx-ab-img-section-2">
                                                <BBQImage src="images/about/pos.jfif" alt="" />
                                            </div>
                                            <div className="sx-ab-img-section-1-wrap">
                                                <div className="sx-ab-video">
                                                    <a href="https://www.youtube.com/watch?v=2KTk8WMRFpE" className="mfp-video circle">
                                                        <i className="flaticon-play" />
                                                    </a>
                                                </div>
                                                </div>
                                            <div className="sx-ab-img-section-2">
                                                <BBQImage src="images/about/pos.jfif" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-content">
                                        <h2 className="sx-title">Why We Are Your Best Choice.</h2>
                                        <span className="sx-title-2">Beyond Expectations: Your Success, Our Mission.</span>
                                        <p>Our track record of successful restaurant solutions, visionary leadership, and a dedicated support team set us apart. We outshine competitors with a proven commitment to excellence and a relentless drive to surpass expectations, making us the top choice for your restaurant POS needs.</p>
                                        <div className="progress">
                                            <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '75%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}><span>95.00% Proven Success Rate</span></div>
                                        </div>
                                        <div className="row sx-about-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <p>Elevate your identity, captivate audiences, and witness your restaurant thrive with our restautant solutions app.</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <p>Achieve seamless efficiency and robust security as we turn  challenges into success stories in the evolving landscape of restaurant technology.</p>
                                            </div>
                                        </div>
                                        <div className="row sx-about-icon-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-media">
                                                        <i className="flaticon-mouse" />
                                                    </div>
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Restaurants</span>
                                                        <span className="sx-icon-bx-title-info">13 Total</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-media">
                                                        <i className="flaticon-download" />
                                                    </div>
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Hotels     </span>
                                                        <span className="sx-icon-bx-title-info">20 Total</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionAboutCompany;