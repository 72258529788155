
import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RestoOutlineIcon from "@mui/icons-material/FoodBankOutlined";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useLocation } from "react-router-dom"; 
import BBQImage from "../../../../elements/bbq-image";

const Sidebar = () => {
  const location = useLocation(); 

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <BBQImage src="images/BBQ_LOGO.png" alt="#" className='header-logo-login cursor-pointer' />
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/config" style={{ textDecoration: "none" }} className={location.pathname === '/config' ? 'active-link' : ''}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">CONFIGURATION</p>
          <Link to="/config/restaurants" style={{ textDecoration: "none" }} className={location.pathname.startsWith('/config/restaurants') ? 'active-link' : ''}>
            <li>
              <RestoOutlineIcon className="icon" />
              <span>Restaurants</span>
            </li>
          </Link>
          <Link to="/config/products" style={{ textDecoration: "none" }} className={location.pathname.startsWith('/config/products') ? 'active-link' : ''}>
            <li>
              <StoreIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <Link  style={{ textDecoration: "none" }} className={location.pathname.startsWith('/config/accounting') ? 'active-link' : ''}>
            <li>
              <StoreIcon className="icon" />
              <span>Accounting & Renewal</span>
            </li>
          </Link>
          <li>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
