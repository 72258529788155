import React, { useState } from 'react';
import { GroupOutlined, MonitorWeight,GroupWorkSharp,FoodBankOutlined,PriceChange,RestaurantMenuSharp } from '@mui/icons-material';
import '../productconfig/product-config.scss';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AddNewSpecialUser from '../../pages/productconfig/special/users/add_new_user';
import { userInputs } from '../../formSource';
import SpecialUsersList from '../../pages/productconfig/special/users/view_users_list';
import UnitsDatatable from '../../components/datatable/unitsDataTable';
import UnitsConversionDatatable from '../../components/datatable/unitsConversionDataTable';
import IngredientsDatatable from '../../components/datatable/ingredientsDataTable';
import RecipesDatatable from '../../components/datatable/recipesDataTable';
import RecipesAndMenuDatatable from '../../components/datatable/recipesMenuDataTable';

const tabsData = [
  
  {
    icon: <GroupOutlined />, label: "User Updation", content: <div style={{ height: '100vh', overflow: 'auto' }}>
      <><AddNewSpecialUser inputs={userInputs} /><SpecialUsersList /></>
    </div>
  },
  { icon: <MonitorWeight />, label: "Unit Updation", content: <UnitsDatatable/> },
  { icon: <PriceChange />, label: "Unit Conversions", content: <UnitsConversionDatatable/> },
  { icon: <FoodBankOutlined />, label: "Ingredients Updation", content: <IngredientsDatatable/> },
  { icon: <GroupWorkSharp />, label: "Reciepe Updation", content:< RecipesDatatable/> },
  { icon: <RestaurantMenuSharp />, label: "Menu Updation", content:< RecipesAndMenuDatatable/> }
];

const InventoryConfigComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="tabs-container">
          <div className="tab-list">
            {tabsData.map((tab, index) => (
              <div
                key={index}
                className={`tab-item ${selectedTab === index ? 'selected' : ''}`}
                onClick={() => handleTabSelect(index)}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </div>
            ))}
          </div>

          <div className="tab-panels">
            {tabsData.map((tab, index) => (
              <div key={index} className={`tab-panel ${selectedTab === index ? 'selected' : ''}`}>
                {selectedTab === index && (
                  <div>
                    {/* <h5>{tab.label}</h5> */}
                    {tab.content}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryConfigComponent;
