import ScrollToTop from "./app/elements/scroll-to-top";
import Loader from "./app/elements/loader";
import { useState } from "react";
import RootLayout from './layouts/root-layout';

function App() {
  const [isLoading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 500);


  return (
    <div className="App">
    <>
      { isLoading && <Loader /> }
      <ScrollToTop />
      <RootLayout/>
    </>
    </div>
  );
}

export default App;
