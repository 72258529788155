import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./datatable.scss";
import axios from "../../../../../api/axios";
import { unitsConversionColumns } from "../../datatablesource";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar
import MuiAlert from '@mui/material/Alert'; // Import Alert for Snackbar

const UnitsConversionDatatable = () => {
  const [unitsData, setUnitsData] = useState([]);
  const [units, setUnits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedConversion, setSelectedConversion] = useState(null);
  const [fromUnitId, setFromUnitId] = useState('');
  const [toUnitId, setToUnitId] = useState('');
  const [conversionRate, setConversionRate] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Snackbar severity
  const errRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const restaurantId = sessionStorage.getItem("configBizEntityId");
    try {
      const responseConversions = await axios.get(`businessEntities/getAllUnitsConversionsViaRestoId/${restaurantId}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setUnitsData(responseConversions.data.units || []);

      const responseUnits = await axios.get(`businessEntities/getAllUnitsViaRestoId/${restaurantId}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setUnits(responseUnits.data.units || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAddUnitConversion = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    resetForm();
  };

  const resetForm = () => {
    setFromUnitId('');
    setToUnitId('');
    setConversionRate('');
    setErrMsg('');
    setSelectedConversion(null);
  };

  const handleEditUnitConversion = (conversion) => {
    setSelectedConversion(conversion);
    setFromUnitId(conversion.fromUnitId);
    setToUnitId(conversion.toUnitId);
    setConversionRate(conversion.conversionRate);
    setOpenModal(true);
  };

  const handleDeleteUnitConversion = (conversion) => {
    setSelectedConversion(conversion);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`businessEntities/deleteUnitConversion/${selectedConversion.id}`, {
        headers: { "Content-Type": "application/json" },
        params: {
          businessEntityId: sessionStorage.getItem("configBizEntityId"),
        },
        withCredentials: false,
      });
      const successMessage = response.data.message || "Unit conversion deleted successfully!";
      fetchData();
      setSnackbarMessage(successMessage);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to delete unit conversion!';
      setOpenDeleteDialog(false);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error('Error deleting conversion:', error);
    } finally {
      setOpenDeleteDialog(false);
      setSelectedConversion(null);
    }
  };

  const handleAddUnitConversionSubmit = async () => {
    if (!fromUnitId || !toUnitId || !conversionRate) {
      setErrMsg("All fields are required.");
      setTimeout(() => setErrMsg(''), 3000);
      return;
    }

    if (fromUnitId === toUnitId) {
      setErrMsg("You cannot use the same unit for conversion.");
      setTimeout(() => setErrMsg(''), 3000);
      return;
    }

    try {
      const url = selectedConversion ? 'businessEntities/updateUnitConversion' : 'businessEntities/addUnitConversion';
      const method = selectedConversion ? 'put' : 'post';
      await axios[method](url, {
        fromUnitId,
        toUnitId,
        conversionRate,
        businessEntityId: sessionStorage.getItem("configBizEntityId"),
        ...(selectedConversion && { id: selectedConversion.id }),
      }, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });

      handleCloseModal();
      fetchData();
      setSnackbarMessage(selectedConversion ? "Unit conversion updated successfully." : "Unit conversion added successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || "Operation failed.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error(error);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="editButton" onClick={() => handleEditUnitConversion(params.row)}>Edit</div>
            <div className="deleteButton" onClick={() => handleDeleteUnitConversion(params.row)}>Delete</div>
          </div>
        );
      },
    },
  ];

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="datatable">
      <div className="datatableTitle" style={{ display: 'flex', alignItems: 'center' }}>
        My Unit Conversions
        <Link className="link" style={{ marginLeft: '10px' }} onClick={handleAddUnitConversion}>
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={unitsData}
        getRowId={(row) => row.id}
        columns={unitsConversionColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-unit-conversion-modal"
        aria-describedby="add-unit-conversion-description"
      >
        <div className="modal-container">
          <h6 id="add-unit-conversion-modal">{selectedConversion ? "Edit Unit Conversion" : "Add New Unit Conversion"}</h6>
          <div>
            <p
              ref={errRef}
              className={errMsg ? "errmsg-login" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <div>
              <label htmlFor="from-unit">From Unit:</label>
              <select
                className="dropdown"
                id="from-unit"
                value={fromUnitId}
                onChange={(e) => setFromUnitId(e.target.value)}
              >
                <option value="">Select a unit</option>
                {units.map((unit) => (
                  <option key={unit.unitId} value={unit.unitId}>
                    {unit.unitName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="to-unit">To Unit:</label>
              <select
                className="dropdown"
                id="to-unit"
                value={toUnitId}
                onChange={(e) => setToUnitId(e.target.value)}
              >
                <option value="">Select a unit</option>
                {units.map((unit) => (
                  <option key={unit.unitId} value={unit.unitId}>
                    {unit.unitName}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <label htmlFor="conversion-rate">Conversion Rate:</label>
              <input
                id="conversion-rate"
                type="text"
                value={conversionRate}
                onChange={(e) => setConversionRate(e.target.value)}
              />
            </div>

            <Button variant="contained" onClick={handleAddUnitConversionSubmit}>
              {selectedConversion ? "Update Conversion" : "Save Conversion"}
            </Button>
          </div>
        </div>
      </Modal>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-confirm-dialog"
      >
        <DialogTitle id="delete-confirm-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this unit conversion?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>



      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default UnitsConversionDatatable;
