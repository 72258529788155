export const restaurantColumns = [
  // {
  //   field: "id", headerName: "ID", width: 30,
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         {params.row.business_entity_id}
  //       </div>
  //     );
  //   }
  // },
  {
    field: "name",
    headerName: "Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={'https://cdn-icons-png.flaticon.com/128/272/272186.png'} alt="avatar" />
          {params.row.business_name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "address",
    headerName: "Address",
    width: 250,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === 'active' ? 'Active' : 'InActive'}
        </div>
      );
    },
  },
];



export const productColumns = [
  // { field: "id", headerName: "ID", width: 70, renderCell: (params) => {
  //   return (
  //     <div>
  //       {params.row.bepm_id}
  //     </div>
  //   );
  // }, },
  {
    field: "product",
    headerName: "Product Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={'https://cdn-icons-png.flaticon.com/128/8189/8189068.png'} alt="avatar" />
          {params.row.productName}
        </div>
      );
    },
  },
  {
    field: "name",
    headerName: "Linked Restaurant",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.businessName}
        </div>

      );
    },
  },
  {
    field: "SDate",
    headerName: "Subscription Date",
    width: 180,
    renderCell: (params) => {
      return (
        <div>
          {'----------'}
        </div>
      );
    },
  },
  {
    field: "eDate",
    headerName: "Expiry Date",
    width: 180,
    renderCell: (params) => {
      return (
        <div>
          {'----------'}
        </div>
      );
    },
  },

];




export const userColumns = [
  // {
  //   field: "id", headerName: "ID", width: 30, renderCell: (params) => {
  //     return (
  //       <div>
  //         {params.row.userid}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={'https://cdn-icons-png.flaticon.com/128/3024/3024605.png'} alt="avatar" />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 220,
    renderCell: (params) => {
      return (
        <div>
          {params.row.email}
        </div>
      );
    },
  },

  {
    field: "phone",
    headerName: "Phone No",
    width: 170,
    renderCell: (params) => {
      return (
        <div>
          {!params.row.phone ? '---' : params.row.phone}
        </div>
      );
    },
  },
  {
    field: "role",
    headerName: "Role",
    width: 120,
    renderCell: (params) => {
      return (
        <div>
          {params.row.role}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div>
          {params.row.status === 0 ? 'Inactive' : 'Active'}
        </div>
      );
    },
  }

];


export const inventoryColumns = [
  {
    field: "name",
    headerName: "Restaurant Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.businessName}
        </div>
      );
    },
  },
];

export const unitsColumns = [
  // {
  //   field: "unitId",
  //   headerName: "Unit ID",
  //   width: 150,
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         {params.row.unitId}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "unitName",
    headerName: "Unit of Measurement",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.unitName}
        </div>
      );
    },
  },
];


export const unitsConversionColumns = [
  {
    field: "unitName",
    headerName: "Unit of Measurement",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {params.row.fromUnitName}
        </div>
      );
    },
  },
  {
    field: "unitConv",
    headerName: "Conversion Unit",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.toUnitName}
        </div>
      );
    },
  },
  {
    field: "convRate",
    headerName: "Conversion Rate",
    width: 430,
    renderCell: (params) => {
      return (
        <div>
          {params.row.conversionRate}     ({`1 ${params.row.fromUnitName} = ${params.row.conversionRate} ${params.row.toUnitName}`})
        </div>
      );
    },
  },
];


export const ingredientsColumns = [
  {
    field: "itemName",
    headerName: "Ingredient Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {params.row.ingredientName}
        </div>
      );
    },
  },
  {
    field: "unit",
    headerName: "Units",
    width: 100,
    renderCell: (params) => {
      return (
        <div>
          {params.row.unitName}
        </div>
      );
    },
  },
  {
    field: "lowAlert",
    headerName: "Low Stock Alert",
    width: 200,
    renderCell: (params) => {
      return (
        <div>{params.row.threshold} {params.row.unitName}
        </div>
      );
    },
  },

  {
    field: "stockDev",
    headerName: "Stock Dev. Limit",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {params.row.deviationLimit}%
        </div>
      );
    },
  },
  // {
  //   field: "expiry",
  //   headerName: "Expiry Date",
  //   width: 200,
  //   renderCell: (params) => {
  //     const expiryDate = new Date(params.row.expiryDate);
  //     const formattedDate = expiryDate.toLocaleDateString();
  //     return (
  //       <div>
  //         {formattedDate}
  //       </div>
  //     );
  //   },
  // },
];


export const reciepeColumns = [
  {
    field: "recipeName",
    headerName: "Recipe Name",
    width: 250,
    renderCell: (params) => {
      return (
        <div style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          {params.row.recipeName}
        </div>
      );
    },
  },

  {
    field: "ingredients",
    headerName: "Ingredients",
    width: 600,
    renderCell: (params) => {
      const ingredients = params.row.ingredients || [];
      if (ingredients.length === 0) {
        return <div>No ingredients available</div>;
      }

      return (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap', 
          gap: '5px',
          alignItems: 'center', 
        }}>
          {ingredients.map((ingredient, index) => (
            <div key={index} style={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px 10px',
              borderRadius: '15px',
              backgroundColor: '#e0e0e0',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}>
              {`${ingredient.ingredientName} - ${Math.round(ingredient.quantity)} ${ingredient.unitName}`}
            </div>
          ))}
        </div>
      );
    },
  },
];


export const recipeMenuColumns = [
  {
    field: "recipeName",
    headerName: "Recipe",
    width: 250,
    renderCell: (params) => {
      return (
        <div style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          {params.row.recipeName}
        </div>
      );
    },
  },

  {
    field: "menuItems",
    headerName: "Menu Items",
    width: 600,
    renderCell: (params) => {
      const menuItems = params.row.menuItems || [];

      return (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          alignItems: 'center',
        }}>
          {menuItems.length > 0 ? (
            menuItems.map((menuItem, index) => (
              <div key={menuItem.menuItemId} style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                borderRadius: '15px',
                backgroundColor: '#adf4bb',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
                {menuItem.menuItem} 
              </div>
            ))
          ) : (
            <div style={{
              padding: '5px 10px',
              borderRadius: '15px',
              backgroundColor: '#f4adad',
              color: '#ffffff',
              whiteSpace: 'nowrap',
            }}>
              No menu items linked
            </div>
          )}
        </div>
      );
    },
  },
];





