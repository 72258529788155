import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";

function TestimonialsPage() {
    return (
        <>
            <Banner _data={bannerData.testimonials} />

            <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
                {/* TESTIMONIAL START */}
                <div className="container">
                    <div className="section-content">
                        {/* TITLE START */}
                        <div className="section-head center">
                            <div className="sx-head-s-title">Our Testimonials</div>
                            <div className="sx-head-l-title">
                                <h2 className="sx-title">What Clients Say?</h2>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="masonry-outer mfp-gallery news-grid clearfix row">
                            {/* COLUMNS 1 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position"> Manager - Quickbay Hotel</span>
                                            <h4 className="testimonial-name">John Waswa</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Working with BuyByeQ has been a game-changer for our hotel. Their POS solutions elevated our hotel presence. Timely support services make them our preferred choice.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 2 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Marketing Director, Zero Oil Biryani</span>
                                            <h4 className="testimonial-name">Delilah Brooklyn</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Choosing BuyByeQ for our POS needs was a strategic decision. Their POS system has greatly contributed to the success of our business. Highly recommended!</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 3 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Business Owner-Hope Restaurant</span>
                                            <h4 className="testimonial-name">Davidson Wahome</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>As a business owner, I appreciate their agility and understanding of the restaurant startup landscape. .Reliable partner for any startup restaurant.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default TestimonialsPage;